import { Store } from "react-notifications-component";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

const NotificationsContainer = () => {
  return <ReactNotifications />;
};

const createNotification = (
  type,
  title,
  message,
  duration = 5000,
  container = "top-right"
) => {
  Store.addNotification({
    title: title,
    message: message,
    type: type,
    insert: "bottom",
    container: container,
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: duration,
      showIcon: true,
    },
  });
};

export { NotificationsContainer, createNotification };
