import { Fragment } from "react";
import Layout from "./components/Layout";
import Home from "./pages/home";

const App = () => {
  return (
    <Fragment>
      <Layout>
        <Home />
      </Layout>
    </Fragment>
  );
};

export default App;
