import { Fragment } from "react";
import { NotificationsContainer } from "../UI/Notification";

const Layout = (props) => {
  return (
    <Fragment>
      <NotificationsContainer />
      <main>{props.children}</main>
    </Fragment>
  );
};

export default Layout;
