import styled from "styled-components";
import { Editor } from "@tinymce/tinymce-react";
import { useRef, useState } from "react";
import { Controlled as CodeMirror } from "react-codemirror2";
import { PuffLoader } from "react-spinners";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { createNotification } from "../components/UI/Notification";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/theme/neat.css";
import "codemirror/mode/javascript/javascript";

const Home = (props) => {
  const [value, setValue] = useState("");
  const [measureValue, setMeasureValue] = useState("");
  const [measureCodeValue, setMeasureCodeValue] = useState("");
  const [isCodeMirror, setIsCodeMirror] = useState(false);
  const [loading, setLoading] = useState(true);
  const editorRef = useRef(null);

  const updateMeasure = (e) => {
    setMeasureValue(e.target.value);
    if (e.target.value) {
      setMeasureCodeValue(e.target.value + " = " + formatCode(value));
    } else {
      setMeasureCodeValue(formatCode(value));
    }
  };

  const formatCode = (code) => {
    let finalValue = ``;
    if (code) {
      finalValue = `\n//This Power BI DAX measure was created by BI Samurai.\n"${code.replace(/"/g, "'")}"`;
    }
    return finalValue;
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(measureCodeValue);
      createNotification("success", "Copied!", "Your text has been copied!");
    } catch (err) {
      createNotification("danger", "Error!", "Failed to copy!");
    }
  };

  return (
    <div className="frame">
      <Heading></Heading>
      {loading && (
        <LoaderWrapper>
          <PuffLoader css={"display: block; margin: 0 auto;"} color="#007bff" />
        </LoaderWrapper>
      )}
      <MainWrapper>
        <EditorWrapper>
          <Editor
            apiKey="ceg4atg282puabct00frmkowj5u17pt4x8mzkhxag3ivagoc"
            onInit={(evt, editor) => {
              setLoading(false);
              editorRef.current = editor;
            }}
            value={value}
            onEditorChange={(newValue, editor) => {
              if (!isCodeMirror) {
                setValue(newValue);
                if (measureValue) {
                  setMeasureCodeValue(
                    measureValue + " = " + formatCode(newValue)
                  );
                } else {
                  setMeasureCodeValue(formatCode(newValue));
                }
              }
            }}
            init={{
              height: 600,
              plugins:
                "preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons",
              toolbar:
                "undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen preview print | insertfile image media link anchor codesample | ltr rtl",
              menubar: "file edit view insert format tools table help",
              toolbar_sticky: false,
              image_advtab: true,
              image_caption: true,
              quickbars_selection_toolbar: "bold italic underline forecolor",
              toolbar_mode: "wrap",
              contextmenu: "link image table",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              quickbars_insert_toolbar: false,
              file_browser_callback_types: "image",
              file_picker_callback: function (cb, value, meta) {
                var input = document.createElement("input");
                input.setAttribute("type", "file");
                input.setAttribute("accept", "image/*");
                input.onchange = function () {
                  var file = this.files[0];

                  var reader = new FileReader();
                  reader.onload = function () {
                    var id = "blobid" + new Date().getTime();
                    var blobCache = editorRef.current.editorUpload.blobCache;
                    var base64 = reader.result.split(",")[1];
                    var blobInfo = blobCache.create(id, file, base64);
                    blobCache.add(blobInfo);

                    /* call the callback and populate the Title field with the file name */
                    cb(blobInfo.blobUri(), { title: file.name });
                  };
                  reader.readAsDataURL(file);
                };
                input.click();
              },
            }}
          />
        </EditorWrapper>
        <CodeMirrorWrapper>
          <CodeMirror
            className="codemirror2-1"
            value={value}
            options={{
              mode: "javascript",
              theme: "xq-light",
              lineNumbers: true,
              lineWrapping: true,
            }}
            onBeforeChange={(editor, data, value) => {
              setIsCodeMirror(true);
              setValue(value);
              if (measureValue) {
                setMeasureCodeValue(measureValue + " = " + formatCode(value));
              } else {
                setMeasureCodeValue(formatCode(value));
              }
            }}
            onChange={(editor, data, value) => {
              setIsCodeMirror(false);
            }}
          />
          {/* <CodeMirror
            className="codemirror2-2"
            value={measureCodeValue}
            options={{
              mode: "javascript",
              theme: "xq-light",
              lineNumbers: true,
              lineWrapping: true,
              readOnly: true,
            }}
          /> */}
          <ButtonWrapper>
            <TextField
              style={{
                flex: "0 0 calc(50% - 5px)",
                marginRight: "5px",
              }}
              label="Measure Name"
              variant="outlined"
              value={measureValue}
              onChange={updateMeasure}
            />
            <Button
              style={{
                flex: "0 0 calc(50% - 5px)",
                marginLeft: "5px",
              }}
              variant="contained"
              onClick={copyToClipboard}
              disabled={!value || !measureValue}
            >
              Copy Code
            </Button>
          </ButtonWrapper>
        </CodeMirrorWrapper>
      </MainWrapper>
    </div>
  );
};

export default Home;

const Heading = styled.div`
  margin-bottom: 20px;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  @media only screen and (max-width: 767px) {
    margin-bottom: 15px;
    font-size: 32px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 24px;
  }
`;

const LoaderWrapper = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 5;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MainWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const EditorWrapper = styled.div`
  width: calc(55% - 7.5px);
  margin-right: 7.5px;
  flex: 1 0 auto;
  @media only screen and (max-width: 991px) {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }
`;

const CodeMirrorWrapper = styled.div`
  width: calc(45% - 7.5px);
  margin-left: 7.5px;
  // .react-codemirror2 .CodeMirror.cm-s-material:first-child,
  // .react-codemirror2 .CodeMirror.cm-s-xq-light:first-child {
  //   display: none;
  // }
  .react-codemirror2 {
    height: calc(100% - 66px);
  }
  .react-codemirror2.codemirror2-1,
  .react-codemirror2.codemirror2-2 {
    margin-bottom: 10px;
  }
  // .react-codemirror2.codemirror2-1,
  // .react-codemirror2.codemirror2-1 .CodeMirror-wrap {
  //   height: 43%;
  // }
  // .react-codemirror2.codemirror2-2,
  // .react-codemirror2.codemirror2-2 .CodeMirror-wrap {
  //   height: 43%;
  // }
  .react-codemirror2.codemirror2-2 .CodeMirror-wrap {
    height: 215px;
  }
  .cm-s-material.CodeMirror,
  .cm-s-xq-light.CodeMirror {
    border-radius: 10px;
    min-height: 100%;
  }
  .cm-s-xq-light.CodeMirror {
    border: 2px solid #eee;
  }
  @media only screen and (max-width: 991px) {
    width: 100%;
    margin-left: 0px;
    .react-codemirror2.codemirror2-1 {
      height: auto;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
